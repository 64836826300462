import { useTheme } from "styled-components";
import { IconProps } from "../Icon.interface";

export const Alert: React.FC<IconProps> = (props) => {
  const { colors } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
      <path
        data-name="Path 17731"
        d="M23.995 11.981A11.983 11.983 0 0 0 21.808 5.1 12.218 12.218 0 0 0 16.061.709 11.973 11.973 0 0 0 2.772 4.335a12.576 12.576 0 0 0-1.91 3.208 11.8 11.8 0 0 0-.826 3.572 11.983 11.983 0 0 0 6.958 11.792 12.4 12.4 0 0 0 3.678 1.017 11.98 11.98 0 0 0 13.16-9.994 12.822 12.822 0 0 0 .166-1.906c0-.005.005-.013.005-.018-.003-.011-.008-.017-.008-.025ZM10.96 5.721a.771.771 0 0 1 .731-.267h.741a.637.637 0 0 1 .686.557.284.284 0 0 1 .008.05v.687c0 .3.029.613-.235.824a1.058 1.058 0 0 1-.712.135h-.689a.624.624 0 0 1-.639-.623c-.008-.224 0-.449 0-.673a1.183 1.183 0 0 1 .109-.69Zm3.858 11.924c0 .09 0 .177-.005.267a.656.656 0 0 1-.47.618 1.488 1.488 0 0 1-.391.024H9.97a.809.809 0 0 1-.562-.153c-.269-.227-.227-.565-.227-.879a1.97 1.97 0 0 1 .077-.879.658.658 0 0 1 .573-.338h.784c.074 0 .172.016.222-.05s.026-.206.026-.277v-4.063a2.337 2.337 0 0 0 0-.272c-.013-.092-.074-.116-.158-.119-.129-.005-.261 0-.391 0-.3 0-.668.055-.91-.166-.261-.235-.214-.581-.214-.9a1.909 1.909 0 0 1 .077-.848c.174-.333.509-.333.831-.333h2.193c.063 0 .129-.008.193-.005a.664.664 0 0 1 .625.465 1.222 1.222 0 0 1 .029.325v6.028c0 .1-.008.19.113.206a7.144 7.144 0 0 0 .752 0 1.265 1.265 0 0 1 .346.024.663.663 0 0 1 .47.57.285.285 0 0 1 .008.05v.7Z"
        fill={colors[props.color || "ALERT"]}
      />
    </svg>
  );
};
