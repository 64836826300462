import styled from 'styled-components';
import { BREAKPOINT_SIZES } from '@app/providers/theme/style';

export const Container = styled.div`
  height: 100%;
  padding: 1rem;
  align-items: center;
  justify-content: center;
`;

export const MaxContentContainer = styled.div`
  height: 100%;
  display: flex;
  margin: 0px auto;
  flex-direction: column;
  max-width: ${BREAKPOINT_SIZES.md}px;
`;

export const Form = styled.form`
  flex: 1;
`;

export const FormRow = styled.div`
  display: block;

  span {
    :first-child {
      margin-right: 0;
    }

    @media ${(p) => p.theme.breakpoints.mobile} {
      :last-child {
        display: block;
        margin-top: 20px;
      }
    }
  }

  @media ${(p) => p.theme.breakpoints.desktop} {
    display: flex;

    span {
      flex: 1;

      :first-child {
        margin-right: 30px;
      }
    }
  }
`;

export const Spacer = styled.div<{ size?: number }>`
  min-height: ${({ size }) => size || 25}px;

  @media (max-width: 380px) {
    min-height: ${({ size }) => size || 10}px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button:first-child {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.TEXT};
    background: ${(p) => p.theme.colors.SUBTLE_FLOATING};
  }

  button:last-child {
    margin: 20px 0;
  }

  @media ${(p) => p.theme.breakpoints.desktop} {
    margin: 0;
    flex-direction: row;

    button:last-child {
      margin: 0;
      margin-left: 20px;
    }
  }
`;

export const Modal = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  min-height: 100vh;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.hexToRGB('#000000', p.theme.isDarkMode ? 0.8 : 0.5)};
`;

export const SuccessContainer = styled.div`
  width: 90%;
  display: flex;
  padding: 20px;
  height: 250px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background: ${(p) => p.theme.colors.SUBTLE_FLOATING};

  button {
    flex: 0;
    height: 40px;
    width: 110px;
    margin-top: 10px;
    font-size: medium;
    border-radius: 5px;

    @media ${(p) => p.theme.breakpoints.xsAndLarger} {
      margin-top: 20px;
    }
  }

  ::before {
    right: -27%;
    content: '';
    width: 200px;
    height: 100px;
    position: absolute;
    transform: rotate(250deg);
    background-position: center;
    background-repeat: no-repeat;
    background: url('/images/bottom-curve.svg');

    @media (max-width: 380px) {
      right: -35%;
    }

    @media (max-width: 320px) {
      right: -40%;
    }
  }

  p {
    opacity: 0.6;
    font-size: 12px;
    line-height: 18px;
  }

  h4 {
    z-index: 5;

    @media (max-width: 380px) {
      max-width: 200px;
    }
  }
`;

export const MaxContentForArticlePreviewContainer = styled(MaxContentContainer)`
  overflow-y: scroll;
  padding-left: 40px;
  padding-right: 32px;
  background: ${(p) => p.theme.colors.SUBTLE_FLOATING};
`;

export const ArticlePreviewModal = styled(Modal)`
  max-height: 100vh;
  overflow-y: hidden;
`;
