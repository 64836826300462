import styled from "styled-components";

export const Em = styled.em`
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  font-family: var(--font-family-spicy);
  color: ${(props) => props.color || "var(--color-text)"};

  .tippy-popper & {
    color: var(--color-background) !important;
  }
`;
