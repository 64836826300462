import { SET_SOUND, SET_LOCALE, SET_COLOR_MODE } from "../types";

import { ISetSound, IColorMode, ISetLocale, ISetColorMode } from "../models";

export const setColorMode = (colorMode: IColorMode): ISetColorMode => ({
  type: SET_COLOR_MODE,
  payload: colorMode,
});

export const setLocale = (locale: ISetLocale["payload"]): ISetLocale => ({
  type: SET_LOCALE,
  payload: locale,
});

export const setSound = (): ISetSound => ({
  type: SET_SOUND,
});
