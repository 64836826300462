import { Article, getArticlePreview, getArticleSlug } from '@app/helpers';
import { network } from './config';

/**
 *
 * @description create evarfinance blog post
 * @function sendArticle
 * @returns Promise<Article>
 */
export const sendArticle = async ({ content, ...article }: Article) => {
  // remove title from markdown file if found
  const markdown = content?.replace(`## ${article.title}`, '');

  return network.post('/blog', {
    ...article,
    markdown,
    subtitle: article.subtitle || null,
    slug: getArticleSlug(article.title),
    preview: getArticlePreview(markdown),
  });
};
