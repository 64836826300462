import { getLocale } from "@app/helpers";
import { SET_SOUND, SET_LOCALE, SET_COLOR_MODE } from "../types";
import { IColorMode, ISettingsState, SettingsActionTypes } from "../models";

export const initialSettingsState: ISettingsState = {
  soundEnabled: true,
  locale: getLocale(),
  colorMode: IColorMode.LIGHT,
};

export const settingReducer = (
  state: ISettingsState = initialSettingsState,
  action: SettingsActionTypes
): ISettingsState => {
  switch (action.type) {
    case SET_COLOR_MODE:
      return { ...state, colorMode: action.payload };

    case SET_SOUND:
      return { ...state, soundEnabled: !state.soundEnabled };

    case SET_LOCALE:
      return { ...state, locale: action.payload };

    default:
      return state;
  }
};
