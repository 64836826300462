import { Icon } from '../Icon';
import { ButtonProps } from './Button.interface';

import { Content, PrimaryButton, SecondaryButton, TertiaryButton } from './Button.styles';

export const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  isLoading = false,
  variant = 'primary',
  ...props
}) => {
  const buttonMapType: Record<typeof variant, React.ReactElement> = {
    primary: (
      <PrimaryButton onClick={onClick} disabled={isLoading} {...(props as any)}>
        <Content>{isLoading ? <Icon name="loading" /> : children}</Content>
      </PrimaryButton>
    ),

    secondary: (
      <SecondaryButton onClick={onClick} disabled={isLoading} {...(props as any)}>
        <Content>{isLoading ? <Icon name="loading" /> : children}</Content>
      </SecondaryButton>
    ),

    tertiary: (
      <TertiaryButton onClick={onClick} disabled={isLoading} {...(props as any)}>
        <Content>{isLoading ? <Icon name="loading" /> : children}</Content>
      </TertiaryButton>
    ),
  };

  return buttonMapType[variant];
};
