import styled from "styled-components";

export const H1 = styled.h1`
  font-size: calc(16 / 16 * 1rem);
  color: var(--color-secondary);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const H2 = styled.h2`
  font-size: calc(32 / 16 * 1rem);
  color: var(--color-tertiary);
  margin-top: 96px;
  margin-bottom: 32px;
`;

export const H3 = styled.h3`
  font-size: calc(25 / 16 * 1rem);
  color: var(--color-gray-900);
  margin-top: 64px;
  margin-bottom: 12px;
`;

export const H4 = styled.h4`
  font-size: calc(20 / 16 * 1rem);
  color: var(--color-gray-900);
  margin-top: 24px;
  margin-bottom: 12px;
`;
