import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

export const Document: React.FC<IconProps> = ({ color, ...props }) => {
  const { colors } = useTheme();

  return (
    <svg
      width={24}
      height={24}
      xmlSpace="preserve"
      viewBox="0 0 84.596 84.596"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={colors[color || 'TEXT']}
        d="M55.285 0H19.767C13.49 0 8.384 5.107 8.384 11.384v61.828c0 6.277 5.107 11.384 11.384 11.384H64.83c6.276 0 11.383-5.106 11.383-11.384v-49.82L55.285 0zm1.657 10.853 9.561 10.686h-9.561V10.853zm7.887 67.743H19.767a5.39 5.39 0 0 1-5.384-5.384V11.384A5.391 5.391 0 0 1 19.767 6h31.175v21.538h19.27v45.674a5.388 5.388 0 0 1-5.383 5.384z"
      />
    </svg>
  );
};
