import axios, { AxiosError } from 'axios';
import { reportError } from '@app/helpers';

// Set config defaults when creating the instance
export const network = axios.create({ baseURL: process.env.REACT_APP_DEFAULT_BASE_URI });

// Add a request interceptor
network.interceptors.request.use(
  (config) => config,
  (error: AxiosError) => {
    reportError(error?.response?.data as Error);
    return Promise.reject(error?.response?.data as Error);
  }
);

// Add a response interceptor
network.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    reportError(error?.response?.data as Error);
    return Promise.reject(error?.response?.data as Error);
  }
);
