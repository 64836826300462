import styled from 'styled-components';

export const Container = styled.div`
  left: 0;
  top: 50%;
  height: 40px;
  display: flex;
  z-index: 99999;
  position: fixed;
  overflow: hidden;
  padding: 0px 15px;
  align-items: center;
  flex-direction: column;
  border-top-right-radius: 10px;
  transition: height 1.5s, top 3s;
  border-bottom-right-radius: 10px;
  box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.3);
  background: ${(p) => p.theme.colors.SUBTLE_FLOATING};

  @media screen and (max-width: 900px) {
    top: 1.5%;
    width: 55px;
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: flex-start;
    transition: width 1.5s, top 3s;

    :hover {
      width: 140px;
    }
  }

  @media screen and (min-width: 900px) {
    :hover {
      top: 40%;
      height: 120px;
    }
  }
`;

export const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin: 12px 0 8px 0;

  @media screen and (max-width: 900px) {
    margin: 0 10px 0 5px;
  }
`;
