import React, { useMemo, useEffect, useCallback, PropsWithChildren } from 'react';
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from 'styled-components';

import { hexToRGB } from '@app/helpers';
import { useDispatch, useSelector } from '@app/providers/store';
import { IColorMode } from '@app/providers/store/settings/models';
import { setColorMode } from '@app/providers/store/settings/actions';

import GlobalStyle from './reset';
import { BREAK_POINTS, DARK_MODE_COLORS, LIGHT_MODE_COLORS } from './style';

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // Get the browser-color mode [if it's dark or light mode]
  const dispatch = useDispatch();
  const { colorMode } = useSelector((state) => state);

  const isDarkMode = colorMode === IColorMode.DARK;

  const toggleTheme = useCallback(
    () =>
      dispatch(setColorMode(colorMode === IColorMode.LIGHT ? IColorMode.DARK : IColorMode.LIGHT)),
    [dispatch, colorMode]
  );

  // Conditionally change the theme-color from dark to light-mode and vise-versa
  const theme: DefaultTheme = useMemo(
    () => ({
      hexToRGB,
      isDarkMode,
      toggleTheme,
      breakpoints: BREAK_POINTS,
      colors: isDarkMode ? DARK_MODE_COLORS : LIGHT_MODE_COLORS,
    }),
    [isDarkMode, toggleTheme]
  );

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia(`(prefers-color-scheme: ${IColorMode.DARK})`)
      .addEventListener('change', (e) =>
        dispatch(setColorMode(e.matches ? IColorMode.DARK : IColorMode.LIGHT))
      );

    // Remove listener
    return () => {
      window
        .matchMedia(`(prefers-color-scheme: ${IColorMode.DARK})`)
        .removeEventListener('change', (e) =>
          dispatch(setColorMode(e.matches ? IColorMode.DARK : IColorMode.LIGHT))
        );
    };
  }, [dispatch]);

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};
