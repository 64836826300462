import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Strong: React.FC<PropsWithChildren> = ({ children }) => {
  return <BaseStrong>{children}</BaseStrong>;
};

export const BaseStrong = styled.strong`
  font-weight: var(--font-weight-bold);
`;
