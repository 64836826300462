import { useState } from 'react';
import { Portal } from 'react-portal';
import { toast } from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';

import { sendArticle } from '@app/network';
import { useValidation } from '@app/hooks';
import { Article, renderMdxComponents } from '@app/helpers';
import { Button, FloatingAction, Input, PreviewArticle } from '@app/components';

import {
  Form,
  Modal,
  Spacer,
  FormRow,
  Container,
  ButtonWrapper,
  SuccessContainer,
  MaxContentContainer,
  ArticlePreviewModal,
  MaxContentForArticlePreviewContainer,
} from './Home.styles';

const defaultArticle: Article = {
  title: '',
  author: '',
  content: '',
  subtitle: '',
};

export const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [article, setArticle] = useState(defaultArticle);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const { errors, validate, resetField } = useValidation(defaultArticle);

  const handleChange = (field: keyof typeof defaultArticle) => {
    return ({ target }: any) => {
      resetField(field);
      setArticle({ ...article, [field]: target.value });
    };
  };

  const handlePreview: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement> = async (
    event
  ) => {
    event.preventDefault();
    if (article.content) {
      setIsReviewOpen(!isReviewOpen);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const hasError = validate(article);
    if (hasError) return;

    try {
      setIsLoading(true);
      await sendArticle(article);
      setIsSuccessful(true);
      toast.success('Article created successfully!');
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsSuccessful(false);
    setArticle({ ...defaultArticle });
  };

  return (
    <Container>
      <MaxContentContainer>
        <Form onSubmit={handleSubmit}>
          <Spacer size={50} />
          <FormRow>
            <span>
              <Input
                required
                label="Title"
                error={errors.title}
                value={article.title}
                onChange={handleChange('title')}
                placeholder="What is on your mind?"
              />
            </span>
            <span>
              <Input
                label="Subtitle"
                value={article.subtitle}
                onChange={handleChange('subtitle')}
                placeholder="How to swaddle your baby"
              />
            </span>
          </FormRow>
          <Spacer />

          <FormRow>
            <span>
              <Input
                required
                label="Author"
                error={errors.author}
                value={article.author}
                placeholder="Tonkiri Tariladei"
                onChange={handleChange('author')}
              />
            </span>
            <span>
              <Input disabled label="Date" defaultValue={new Date().toDateString()} />
            </span>
          </FormRow>
          <Spacer />

          <Spacer />
          <Input
            required
            label="Content"
            inputtype="textarea"
            error={errors.content}
            value={article.content}
            onChange={handleChange('content')}
            placeholder="Markdown content in a text format"
          />

          <PreviewArticle article={article.content} handlePreview={handlePreview} />

          <Spacer size={30} />
          <Spacer size={30} />
          <ButtonWrapper>
            <Button type="reset" disabled={isLoading} onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" isLoading={isLoading}>
              Save
            </Button>
          </ButtonWrapper>
        </Form>
      </MaxContentContainer>

      {isSuccessful && (
        <Portal>
          <Modal onClick={closeModal}>
            <SuccessContainer>
              <Spacer />
              <h4>What a nice addition to our server.</h4>
              <h5>Lookin' real spiffy in here.</h5>

              <Spacer />
              <p>Upload was successful, updates from this request will</p>
              <p>Now show up directly on the blog server</p>
              <Button>Got it!</Button>
            </SuccessContainer>
          </Modal>
        </Portal>
      )}

      {isReviewOpen && (
        <Portal>
          <ArticlePreviewModal onClick={handlePreview}>
            <MaxContentForArticlePreviewContainer>
              <Spacer />
              <ReactMarkdown
                //@ts-ignore
                components={renderMdxComponents}
              >
                {article?.content!!}
              </ReactMarkdown>
              <Spacer />
              <Spacer />
              <Spacer />
            </MaxContentForArticlePreviewContainer>
          </ArticlePreviewModal>
        </Portal>
      )}

      <Portal>
        <FloatingAction />
      </Portal>
    </Container>
  );
};
