import React from 'react';

export const Error = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={13.369} {...props}>
      <path
        data-name="Subtraction 1"
        d="M13.506 13.369H2.494a2.659 2.659 0 0 1-2.124-.988 1.806 1.806 0 0 1-.11-2.029l5.506-9.2A2.574 2.574 0 0 1 8 0a2.574 2.574 0 0 1 2.234 1.154l5.506 9.2a1.806 1.806 0 0 1-.11 2.029 2.623 2.623 0 0 1-2.124.986Zm-5.923-3.355v.835h.835v-.835ZM8 3.341a.418.418 0 0 0-.417.417v5.006a.417.417 0 0 0 .835 0V3.758A.418.418 0 0 0 8 3.341Z"
        fill="#fa235d"
      />
    </svg>
  );
};
