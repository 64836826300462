import { Em } from '../components/Em';
import { Strong } from '../components/Strong';
import Blockquote from '../components/BlockQuote';
import { PostImage } from '../components/PostImage';
import { Paragraph } from '../components/paragraph';
import { H1, H2, H3, H4 } from '../components/Heading';
import Strikethrough from '../components/Strikethrough';

export const renderMdxComponents = {
  i: Em,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  em: Em,
  p: Paragraph,
  img: PostImage,
  strong: Strong,
  strike: Strikethrough,
  blockquote: Blockquote,
};
