import { Portal } from 'react-portal';

import { Home } from '@app/pages/home';
import { Toaster } from '@app/components';
import { Providers } from '@app/providers';

export default function App() {
  return (
    <Providers>
      <Home />
      <Portal>
        <Toaster />
      </Portal>
    </Providers>
  );
}
