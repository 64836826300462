import { IconProps } from '../Icon.interface';

export const Cancel: React.FC<IconProps> = ({ color, ...props }) => {
  return (
    <svg width={70} height={70} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M55.505 14.495C50.025 9.015 42.75 6 35 6c-7.75 0-15.025 3.015-20.505 8.495C9.015 19.975 6 27.25 6 35c0 7.75 3.015 15.025 8.495 20.505C19.975 60.985 27.25 64 35 64c7.75 0 15.025-3.015 20.505-8.495C60.985 50.025 64 42.75 64 35c0-7.75-3.015-15.025-8.495-20.505Zm-2.331 38.679c-4.858 4.858-11.315 7.53-18.174 7.53-6.86 0-13.316-2.672-18.174-7.53-10.02-10.02-10.02-26.327 0-36.348 4.858-4.858 11.315-7.53 18.174-7.53 6.86 0 13.316 2.672 18.174 7.53 10.02 10.02 10.02 26.327 0 36.348Z"
        fill="#D92121"
      />
      <path
        d="M47.778 22.22a1.643 1.643 0 0 0-2.332 0L35 32.67 24.55 22.22a1.643 1.643 0 0 0-2.331 0 1.643 1.643 0 0 0 0 2.331L32.666 35 22.22 45.448a1.643 1.643 0 0 0 0 2.331c.318.317.745.488 1.16.488.415 0 .842-.158 1.16-.488l10.447-10.448L45.434 47.78c.318.317.745.488 1.16.488.427 0 .842-.158 1.16-.488a1.643 1.643 0 0 0 0-2.331L37.33 35l10.448-10.448a1.643 1.643 0 0 0 0-2.331Z"
        fill="#D92121"
      />
    </svg>
  );
};
