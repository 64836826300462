import { Moon } from './assets/moon';
import { Error } from './assets/error';
import { Alert } from './assets/alert';
import { Cancel } from './assets/cancel';
import { Loading } from './assets/loading';
import { Preview } from './assets/preview';
import { Document } from './assets/document';

import { IconProps } from './Icon.interface';

const iconMap = {
  moon: Moon,
  alert: Alert,
  error: Error,
  cancel: Cancel,
  loading: Loading,
  preview: Preview,
  document: Document,
};

export type IconType = keyof typeof iconMap;

const resolver: Record<string, any> = {};

export const iconNameList = Object.keys(iconMap) as IconType[];

export const Icon = ({ name, size, ...props }: IconProps & { name: IconType }) => {
  const style = size
    ? { height: size, width: size, viewBox: `0 0 ${size * 1.2} ${size * 1.2}` }
    : {};

  for (const [key, icon] of Object.entries(iconMap)) {
    resolver[key] = icon({ size, ...props, ...style });
  }

  return resolver[name];
};
