import styled from "styled-components";
import { IconProps } from "../Icon.interface";

const Spinner = styled.div<Pick<IconProps, "size">>`
  margin: 0 2rem;
  border-radius: 50%;
  animation-duration: 1s;
  background: transparent;
  width: ${({ size }) => size}px;
  animation-name: rotate-s-loader;
  height: ${({ size }) => size}px;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 5px solid
    ${({ theme }) =>
      theme.isDarkMode
        ? theme.hexToRGB(theme.colors.INPUT_BACKGROUND, 0.7)
        : theme.colors.GRAY_200};
  border-left-color: ${({ theme }) =>
    theme.isDarkMode ? theme.colors.TEXT : theme.colors.MEDIUMS_LATE_BLUE};

  @keyframes rotate-s-loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Loading: React.FC<IconProps> = ({ size = 25 }) => (
  <Spinner className="spinner" size={size} />
);
