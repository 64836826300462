import { createContext, Dispatch } from "react";

import { initialSettingsState } from "./settings/reducers";
import { ISettingsState, SettingsActionTypes } from "./settings/models";

// We define our type for the context dispatch types right here
export type DispatchTypes = SettingsActionTypes;

// We define our type for the context properties right here
export interface ContextState extends ISettingsState {}

// We define our type for the context properties right here
export interface ContextProps {
  store: ContextState;
  dispatch: Dispatch<DispatchTypes>;
}

const StoreContext = createContext<ContextProps>({
  dispatch: () => {},
  store: initialSettingsState,
});

export default StoreContext;
