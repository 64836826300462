import styled from 'styled-components';

export const PrimaryButton = styled.button`
  flex: 1;
  color: #fff;
  height: 4rem;
  display: flex;
  cursor: pointer;
  font-weight: 500;
  font-weight: 800;
  font-size: 1.2rem;
  align-items: center;
  flex-direction: row;
  border-radius: 50px;
  padding: 0.625rem 1.25rem;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.MEDIUMS_LATE_BLUE};

  :focus {
    color: ${({ theme }) => theme.colors.TEXT} !important;
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.TEXT};
  background-color: ${({ theme }) => theme.colors.THISTLE};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.TEXT};
  }
`;

export const TertiaryButton = styled(PrimaryButton)`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.TEXT};
`;

export const Content = styled.div`
  margin: 0 auto;
`;
