import { Fragment } from 'react';
import prettyBytes from 'pretty-bytes';

import { Icon } from '../Icon';
import { PreviewArticleProps } from './PreviewArticle.interface';

import {
  Spacer,
  Document,
  DocumentMeta,
  PreviewButton,
  DocumentWrapper,
  DocumentPreview,
  PreviewContainer,
} from './PreviewArticle.styles';

export const PreviewArticle: React.FC<PreviewArticleProps> = ({ article, handlePreview }) => {
  return (
    <Fragment>
      <Spacer size={30} />
      <PreviewContainer>
        <Document>
          <DocumentWrapper>
            <Icon name="document" />
          </DocumentWrapper>
          <DocumentMeta>
            <h5>document.md</h5>
            <p>{prettyBytes(new Blob([article || '']).size)}</p>
          </DocumentMeta>
        </Document>
        <>
          <DocumentPreview>
            <PreviewButton onClick={handlePreview}>
              <Icon name="preview" />
              <h5>Preview</h5>
            </PreviewButton>
          </DocumentPreview>
        </>
      </PreviewContainer>
    </Fragment>
  );
};
