import { useTheme } from 'styled-components';
import { useSelector, useDispatch } from '@app/providers/store';
import { setSound } from '@app/providers/store/settings/actions';

import { SoundToggle } from '../SoundToogle';
import { DarkModeToggle } from '../DarkModeToggle';

import { Container, Logo } from './FloatingAction.styles';

export const FloatingAction: React.FC = () => {
  const dispatch = useDispatch();
  const { isDarkMode, toggleTheme } = useTheme();
  const toggleSound = () => dispatch(setSound());
  const { soundEnabled } = useSelector((state) => state);

  return (
    <Container>
      <Logo alt="app logo" src="/images/logo.svg" />
      <DarkModeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
      <SoundToggle toggleSound={toggleSound} soundEnabled={soundEnabled} />
    </Container>
  );
};
