import React, { useReducer, useContext, useEffect } from "react";

import { usePersistedState } from "@app/hooks";

import { settingReducer, initialSettingsState } from "./settings/reducers";
import StoreContext, { ContextProps } from "./interface";

type StoreProviderProps = { children: React.ReactNode };

const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const [persistedSettings, setPersistedSettings] = usePersistedState(
    "settings",
    initialSettingsState
  );

  const [store, dispatch] = useReducer(settingReducer, persistedSettings);

  useEffect(() => {
    setPersistedSettings(store);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <StoreContext.Provider value={{ store, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

function useSelector<TSelected = unknown>(
  selector: (state: ContextProps["store"]) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  const { store } = useContext(StoreContext);
  return selector(store);
}

const useStore = () => {
  const { store } = useContext(StoreContext);
  return store;
};

const useDispatch = () => {
  const { dispatch } = useContext(StoreContext);
  return dispatch;
};

export { StoreProvider, useStore, useDispatch, useSelector };
