import styled from 'styled-components';

export const Paragraph = styled.p`
  opacity: 1;
  margin-bottom: 2em;
  letter-spacing: -0.003em;
  font-size: calc(19 / 16 * 1rem);
  line-height: calc(1em + 0.725rem);
  -webkit-font-smoothing: antialiased;
  font-weight: var(--font-weight-light);

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    font-size: calc(18 / 16 * 1rem);
    margin-bottom: 1.5rem;
  }
`;
