/*
 ****************************************************************
 ******************    ALL APP CONSTANTS   **********************
 ****************************************************************
 */

/**
 * extending app global constants for page loading
 * @constant LANGUAGE_DEFAULT default value
 */
export const LANGUAGE_DEFAULT: string = 'en';

/**
 * extending app global constants
 * @constant COLOR_SWAP_TRANSITION_DURATION default value
 */
export const COLOR_SWAP_TRANSITION_DURATION: number = 350;

/**
 * extending app global constants
 * @constant __ROOT_CONTEXT_STATE_KEY__ default value
 */
export const __ROOT_CONTEXT_STATE_KEY__: string = `@CONTEXT_LOCAL_STATE_PERSIST_KEY`;
