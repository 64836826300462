// import original module declarations
import 'styled-components';

export const BREAKPOINT_SIZES = {
  xs: 320,
  sm: 563,
  md: 768,
  lg: 1024,
  xl: 1440,
};

export const BREAK_POINTS = {
  xs: `(max-width: ${BREAKPOINT_SIZES.xs}px)`,
  mobile: `(max-width: ${BREAKPOINT_SIZES.md - 1}px)`,
  desktop: `(min-width: ${BREAKPOINT_SIZES.md}px)`,
  xsAndSmaller: `(max-width: ${BREAKPOINT_SIZES.xs}px)`,
  smAndSmaller: `(max-width: ${BREAKPOINT_SIZES.sm}px)`,
  mdAndSmaller: `(max-width: ${BREAKPOINT_SIZES.md}px)`,
  lgAndSmaller: `(max-width: ${BREAKPOINT_SIZES.lg}px)`,
  xlAndSmaller: `(max-width: ${BREAKPOINT_SIZES.xl}px)`,
  xsAndLarger: `(min-width: ${BREAKPOINT_SIZES.xs + 1}px)`,
  smAndLarger: `(min-width: ${BREAKPOINT_SIZES.sm + 1}px)`,
  mdAndLarger: `(min-width: ${BREAKPOINT_SIZES.md + 1}px)`,
  lgAndLarger: `(min-width: ${BREAKPOINT_SIZES.lg + 1}px)`,
  xlAndLarger: `(min-width: ${BREAKPOINT_SIZES.xl + 1}px)`,
  sm: `(min-width: ${BREAKPOINT_SIZES.xs}px and max-width: ${BREAKPOINT_SIZES.sm}px)`,
  md: `(min-width: ${BREAKPOINT_SIZES.sm}px and max-width: ${BREAKPOINT_SIZES.md}px)`,
  lg: `(min-width: ${BREAKPOINT_SIZES.md}px and max-width: ${BREAKPOINT_SIZES.lg}px)`,
  xl: `(min-width: ${BREAKPOINT_SIZES.lg}px and max-width: ${BREAKPOINT_SIZES.xl}px)`,
};

// All app colorss
export enum LIGHT_MODE_COLORS {
  ERROR = '#FA235D',
  ALERT = '#ff805c',
  THISTLE = '#CFC8FB',
  TRANSPARENT = 'transparent',
  INPUT_BACKGROUND = '#F7F6FF',
  MEDIUMS_LATE_BLUE = '#7D69FF',
  TEXT = 'hsl(222deg, 22%, 5%)',
  INFO = 'hsl(245deg, 100%, 60%)',
  GRAY_300 = 'hsl(225deg, 8%, 80%)',
  GRAY_400 = 'hsl(225deg, 8%, 70%)',
  GRAY_500 = 'hsl(225deg, 7%, 60%)',
  PRIMARY = 'hsl(245deg, 100%, 60%)',
  TERTIARY = 'hsl(255deg, 85%, 30%)',
  GRAY_100 = 'hsl(225deg, 25%, 95%)',
  GRAY_200 = 'hsl(225deg, 16%, 90%)',
  GRAY_600 = 'hsl(225deg, 15%, 50%)',
  GRAY_700 = 'hsl(225deg, 12%, 40%)',
  GRAY_900 = 'hsl(225deg, 25%, 20%)',
  GRAY_1000 = 'hsl(225deg, 15%, 15%)',
  SECONDARY = 'hsl(333deg, 100%, 45%)',
  BACKGROUND = 'hsl(225deg, 30%, 95%)',
  SUBTLE_FLOATING = 'hsl(0deg, 0%, 100%)',
}

export enum DARK_MODE_COLORS {
  ERROR = '#FA235D',
  ALERT = '#ff805c',
  THISTLE = '#CFC8FB',
  TRANSPARENT = 'transparent',
  INPUT_BACKGROUND = '#2b343b',
  MEDIUMS_LATE_BLUE = '#7D69FF',
  TEXT = 'hsl(0deg, 0%, 100%)',
  INFO = 'hsl(230deg, 100%, 67%)',
  GRAY_400 = 'hsl(210deg, 9%, 45%)',
  GRAY_500 = 'hsl(210deg, 8%, 50%)',
  PRIMARY = 'hsl(230deg, 100%, 67%)',
  GRAY_200 = 'hsl(210deg, 15%, 25%)',
  TERTIARY = 'hsl(53deg, 100%, 50%)',
  GRAY_100 = 'hsl(210deg, 15%, 20%)',
  GRAY_300 = 'hsl(210deg, 10%, 40%)',
  GRAY_600 = 'hsl(210deg, 12%, 55%)',
  GRAY_700 = 'hsl(210deg, 14%, 66%)',
  GRAY_900 = 'hsl(210deg, 25%, 88%)',
  BACKGROUND = 'hsl(210deg, 30%, 8%)',
  GRAY_1000 = 'hsl(210deg, 25%, 96%)',
  SECONDARY = 'hsl(333deg, 100%, 52%)',
  SUBTLE_FLOATING = 'hsl(210deg, 22%, 15%)',
}

declare module 'styled-components' {
  export interface DefaultTheme {
    // App dark mode condition
    isDarkMode: boolean;
    // Toggle App theme
    toggleTheme: () => void;
    // App break points
    breakpoints: typeof BREAK_POINTS;
    // All Global App Colors typings
    colors: typeof DARK_MODE_COLORS | typeof LIGHT_MODE_COLORS;
    // Toggle App theme
    hexToRGB: (color: string, opacity: number) => string;
  }
}
