import { useState } from 'react';
import { Article, validator } from '@app/helpers';

export const useValidation = (defaultArticle: Article) => {
  const [errors, setErrors] = useState(defaultArticle);

  const resetField = (field: keyof Article) => {
    if (errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
  };

  const validate = (article: Article): boolean => {
    const { error } = validator(article);
    if (error) {
      setErrors(error);
    }
    return !!error;
  };

  return { errors, resetField, validate };
};
