import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

export const Preview: React.FC<IconProps> = ({ color, ...props }) => {
  const { colors } = useTheme();

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 96 96"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.294 92h10.893c1.711 0 3.099-1.289 3.099-3s-1.387-3-3.099-3H18.294C13.708 86 10 81.93 10 77.169V33h75v11.282c0 1.711 1.789 3.099 3.5 3.099s3.5-1.387 3.5-3.099V18.894C92 10.821 84.413 4 76.426 4H18.294C10.291 4 4 10.681 4 18.894v58.275C4 85.347 10.291 92 18.294 92zM10 18.894C10 14.18 13.786 10 18.294 10h58.132C81.053 10 85 14.18 85 18.894V27H10z"
        fill={colors[color || 'TEXT']}
      />
      <path
        d="M74.427 16h-1.239c-1.711 0-3.099 1.789-3.099 3.5s1.387 3.5 3.099 3.5h1.239c1.711 0 3.099-1.789 3.099-3.5S76.138 16 74.427 16zM63.892 16h-1.239c-1.711 0-3.099 1.789-3.099 3.5s1.387 3.5 3.099 3.5h1.239c1.711 0 3.099-1.789 3.099-3.5S65.603 16 63.892 16zM52.737 16h-1.239c-1.711 0-3.099 1.789-3.099 3.5s1.387 3.5 3.099 3.5h1.239c1.711 0 3.099-1.789 3.099-3.5S54.448 16 52.737 16zM60.997 47.135C47.409 47.135 35 55.668 30 68.369c0 0 6.505 23.954 30.997 23.432C86.875 91.25 92 68.369 92 68.369c-5-12.701-17.411-21.234-31.003-21.234zm.221 38.469c-10.634 0-20.346-6.437-24.536-16.136 4.189-9.699 13.902-16.136 24.536-16.136 10.637 0 20.351 6.437 24.541 16.136-4.189 9.699-13.904 16.136-24.541 16.136z"
        fill={colors[color || 'TEXT']}
      />
      <path
        d="M61.221 57.445c-6.63 0-12.024 5.393-12.024 12.023s5.394 12.023 12.024 12.023 12.023-5.393 12.023-12.023-5.393-12.023-12.023-12.023zm0 17.849c-3.213 0-5.827-2.614-5.827-5.826s2.614-5.826 5.827-5.826 5.826 2.614 5.826 5.826-2.613 5.826-5.826 5.826z"
        fill={colors[color || 'TEXT']}
      />
    </svg>
  );
};
