import React, { useState, HTMLProps } from 'react';
import { Caption, ImageSkeleton, ImageElem, Wrapper } from './PostImage.style';
type BaseImageProps = {
  type?: string;
  source?: string;
  fallback?: string;
  marginBottom?: number;
  includeWhiteBackground?: boolean;
} & HTMLProps<HTMLImageElement>;
const BaseImage: React.FC<BaseImageProps> = ({
  src,
  type,
  width,
  title,
  height,
  source,
  marginBottom,
  includeWhiteBackground,
  ...delegated
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const as = typeof width !== 'undefined' && typeof height !== 'undefined' ? Image : 'img';
  const wrapperStyle = {
    padding: includeWhiteBackground && '16px',
    background: includeWhiteBackground && 'white',
    borderRadius: includeWhiteBackground && '8px',
    '--margin-bottom': typeof marginBottom === 'number' ? `${marginBottom}px` : undefined,
  };
  if (source) {
    return (
      <Wrapper
        type={type}
        //@ts-ignore
        style={wrapperStyle}
      >
        <a href={source} target="_blank" rel="noopener noreferrer">
          <ImageSkeleton isLoading={isLoading} />
          <ImageElem
            //@ts-ignore
            as={as}
            src={src}
            width={width}
            height={height}
            isLoading={isLoading}
            onLoad={() => setIsLoading(false)}
            {...delegated}
          />
        </a>
        {title && <Caption>{title}</Caption>}
      </Wrapper>
    );
  }
  return (
    <Wrapper
      type={type}
      //@ts-ignore
      style={wrapperStyle}
    >
      <ImageSkeleton isLoading={isLoading} />
      <ImageElem
        //@ts-ignore
        as={as}
        src={src}
        width={width}
        height={height}
        isLoading={isLoading}
        onLoad={() => setIsLoading(false)}
        {...delegated}
      />
      {title && <Caption>{title}</Caption>}
    </Wrapper>
  );
};
export const PostImage: React.FC<BaseImageProps> = ({
  src,
  alt = '',
  fallback,
  title = '',
  type = 'default',
  ...delegated
}) => <BaseImage src={src} type={type} title={title} fallback={fallback} {...delegated} />;
