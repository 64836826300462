/**
 *
 * @description A function that helps validates form fields
 * @function validator
 * @returns string
 */

export type Article = {
  id?: string;
  slug?: string;
  title: string;
  author: string;
  preview?: string;
  content?: string;
  markdown?: string;
  subtitle?: string;
  updated_at?: string;
  created_at?: string;
};

export const validator = ({ subtitle, ...article }: Article): { error: Article | null } => {
  const errors = Object.entries(article).reduce((acc, [key, value]) => {
    if (!acc[key as keyof Article] && !value) {
      let capitalizedKey = key.substring(0, 1).toUpperCase() + key.substring(1);
      acc[key as keyof Article] = `${capitalizedKey} is required`;
    }

    return acc;
  }, {} as Article);

  return { error: Object.keys(errors).length ? errors : null };
};
