import { animated } from 'react-spring';
import styled from 'styled-components';

export const IconWrapper = styled.button`
  width: 40px;
  opacity: 0.7;
  height: 40px;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.TRANSPARENT};

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    &:hover {
      opacity: 1;
    }
  }
`;

export const MoonOrSun = styled(animated.svg)`
  position: relative;
  overflow: visible;
`;
