import removeMarkdown from 'remove-markdown';

const NEW_LINE_PATTERN = /\r?\n|\r/g;
const IMAGE_PATTERN = /!\[.*?\]\((.*)\)\s/g;
const LINK_PATTERN = /\[(.+)\]\((https?:\/\/[^\s]+)(?: "(.+)")?\)|(https?:\/\/[^\s]+)/gi;

export const getArticlePreview = (content?: string): string => {
  if (!content) return '';

  const markdownWithOutImages = content.replace(IMAGE_PATTERN, ' ');
  const markdownWithOnlyText = markdownWithOutImages.replace(LINK_PATTERN, ' ');
  const markdown = markdownWithOnlyText.replace(NEW_LINE_PATTERN, '');
  const plainText = removeMarkdown(markdown);
  return `${plainText.substring(0, 250)}...`;
};
