import styled, { css } from 'styled-components';

export const Container = styled.div<{ inputtype?: string; error?: string }>`
  display: flex;
  padding: 0 15px;
  border-radius: 8px;
  align-items: center;
  background: ${(p) => p.theme.colors.SUBTLE_FLOATING};
  height: ${({ inputtype }) => (inputtype === 'textarea' ? 15 : 3)}rem;

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.hexToRGB(theme.colors.MEDIUMS_LATE_BLUE, 0.4)} !important;
  }

  input:focus,
  input:disabled {
    background: none;
  }

  @media ${(p) => p.theme.breakpoints.mdAndLarger} {
    height: ${({ inputtype }) => (inputtype === 'textarea' ? 15 : 4)}rem;
  }

  ${({ error, theme }) => {
    if (error) {
      return css`
        border: 2px solid ${theme.hexToRGB(theme.colors.ERROR, 0.4)} !important;
      `;
    }
  }}
`;

export const BaseInput = styled.input`
  flex: 1;
  width: 100%;
  border: none;
  resize: none;
  height: 100%;
  outline: none;
  margin: 0 3px;
  font-size: 1rem;
  font-weight: 500;
  font-family: inherit;

  color: ${({ theme }) => theme.colors.TEXT};
  background-color: ${({ theme }) => theme.colors.TRANSPARENT};

  ::placeholder {
    color: ${({ theme }) => theme.colors.GRAY_500};
  }
`;

export const BaseTextArea = styled.textarea`
  flex: 1;
  width: 100%;
  border: none;
  resize: none;
  height: 100%;
  outline: none;
  margin: 0 3px;
  padding: 15px 0;
  font-size: 1rem;
  font-weight: 500;
  font-family: inherit;
  color: ${({ theme }) => theme.colors.TEXT};
  background-color: ${({ theme }) => theme.colors.TRANSPARENT};

  ::placeholder {
    color: ${({ theme }) => theme.colors.GRAY_500};
  }
`;

export const ErrorMessage = styled.small`
  display: block;
  font-size: 0.9rem;
  margin-top: 0.2rem;
  color: ${({ theme }) => theme.hexToRGB(theme.colors.ERROR, 0.9)};
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;
  align-items: center;
  display: inline-flex;

  span {
    padding-left: 3px;
    margin: 0 !important;
    color: ${({ theme }) => theme.colors.ERROR};
  }
`;
