import React from 'react';
import { Icon } from '../Icon';

import { InputBaseProps } from './Input.interface';
import { Label, Container, BaseInput, BaseTextArea, ErrorMessage } from './Input.styles';

//@ts-ignore
export const Input: InputBaseProps = React.forwardRef((props, ref) => {
  const { type, required, inputtype, error, label, children, ...restProps } = props;

  return (
    <React.Fragment>
      {label && (
        <Label htmlFor={type}>
          {label} {required && <span>*</span>}
        </Label>
      )}
      <Container inputtype={inputtype} error={error}>
        {inputtype === 'textarea' ? (
          <BaseTextArea
            //@ts-ignore
            ref={ref}
            {...restProps}
            className="input__field"
          />
        ) : (
          <BaseInput
            //@ts-ignore
            ref={ref}
            {...restProps}
            className="input__field"
          />
        )}
        {children}
        {error && <Icon name="error" />}
      </Container>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </React.Fragment>
  );
});
