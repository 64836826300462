import styled from 'styled-components';
import { Button } from '../Button';

export { Spacer } from '@app/pages/home/Home.styles';

export const DocumentMeta = styled.div`
  height: 100%;
  display: flex;
  padding: 4px 0;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-around;

  h5 {
    font-size: 0.9rem;
    line-height: normal;
  }

  p {
    opacity: 0.5;
    font-size: 0.9rem;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const Document = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DocumentWrapper = styled.div`
  width: 75px;
  height: 90%;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.BACKGROUND};
`;

export const PreviewContainer = styled.div`
  height: 100px;
  display: flex;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
  background: ${(p) => p.theme.colors.SUBTLE_FLOATING};

  @media screen and (max-width: 400px) {
    height: 150px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DocumentPreview = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 400px) {
    width: 100%;
    margin-top: 15px;
  }
`;

export const PreviewButton = styled(Button)`
  flex: 0;
  height: 90%;
  width: 100%;
  font-size: 0.9rem;
  border-radius: 10px;
  padding: 0.625rem 2.5rem;
  color: ${(p) => p.theme.colors.TEXT};
  background: ${(p) => p.theme.colors.BACKGROUND};

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 400px) {
    flex: 1;
    width: 100%;
  }
`;
